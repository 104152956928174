import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "about-accordions",
      "style": {
        "position": "relative"
      }
    }}>{`About accordions`}</h2>
    <p>{`Accordions are composed of a list of items, with a header button that can be
clicked to toggle the visibility of extra content.`}</p>
    <h2 {...{
      "id": "behaviour",
      "style": {
        "position": "relative"
      }
    }}>{`Behaviour`}</h2>
    <ul>
      <li parentName="ul">{`Each item's header is focusable`}</li>
      <li parentName="ul">{`Content's visibility can togglebale by pressing `}<inlineCode parentName="li">{`Enter`}</inlineCode>{` and `}<inlineCode parentName="li">{`Space`}</inlineCode></li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Accordion id="myAccordion">
  <AccordionItem title="Accordion item 1">
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <TextField label="My form" />
      <Button mt={5}>Submit</Button>
    </Flex>
  </AccordionItem>
  <AccordionItem title="Accordion item 2">
    <Paragraph>
      Morbi varius sed libero pulvinar faucibus. Fusce molestie nisi id tellus
      dignissim, quis elementum urna varius. Phasellus blandit volutpat diam,
      non fringilla purus ornare id.
    </Paragraph>
  </AccordionItem>
  <AccordionItem title="Accordion item 3">
    <img
      src="http://placekitten.com/500/200"
      alt="cat"
      style={{
        maxWidth: '100%',
        height: 'auto',
        width: 'auto',
      }}
    />
  </AccordionItem>
</Accordion>
`}</code></pre>
    <h2 {...{
      "id": "guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines`}</h2>
    <ul>
      <li parentName="ul">{`Should be used to help users navigate and digest large blocks of information.`}</li>
      <li parentName="ul">{`Do not hide important information in the expandable area.`}</li>
    </ul>
    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}</h2>
    <p>{`The content of an accordion has to be available for assistive technology.
Therefore they require the use of `}<inlineCode parentName="p">{`aria-controls`}</inlineCode>{` and `}<inlineCode parentName="p">{`aria-expanded`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      